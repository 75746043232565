import { SYSTEM_DATE_FORMAT, UPDATE_DATE_FORMAT } from "constants/constants";

import { ColumnsType } from "antd/es/table";
import { CustomSortIcon } from "components/CustomSortIcon";
import { CustomTooltip } from "components/CustomTooltip";
import DangerIconTriangle from "assets/icons/DangerIconTriangle.svg";
import { EdoMoreActionButton } from "../components/EdoMoreActionButton";
import { ExpandedChildDataType } from "../interfaces";
import { StatusLabel } from "components/StatusLabel";
import { Typography } from "antd";
import { UserRole } from "interfaces/common-model";
import { dateSorter } from "libs/helper";
import dayjs from "dayjs";
import { normalizeForEdi, reversedEDO_STATUS } from "../helpers";
import { useMemo } from "react";
import { useUserRole } from "../components/SelectRole";

export const useSubTableColumn = (): ColumnsType<ExpandedChildDataType> => {
  const role = useUserRole();

  const columns = useMemo<ColumnsType<ExpandedChildDataType>>(
    () => [
      {
        render: () => (
          <div className="w-[41px] h-[94px] top-[-63px] left-[-92px] border border-solid border-blue-solitude-second border-t-0 border-r-0 absolute rounded-bl-[8px]"></div>
        ),
        width: 0,
      },
      {
        title: (
          <div>
            <p className="p-[0px] m-[0px]">Container No.</p>
          </div>
        ),
        dataIndex: "containerDetail",
        sortIcon: CustomSortIcon,
        render: (item) => (
          <>
            <div className="flex flex-col w-[100px]">
              <Typography.Text className="truncate font-roboto text-sm text-blue-midnight-express leading-5 font-normal">
                {item.containerNumber}
              </Typography.Text>
              <Typography.Text className="truncate font-roboto text-xs text-blue-waikawa-grey leading-5 font-normal">
                <div className="flex items-stretch">
                  {item.isDG && (
                    <CustomTooltip
                      copytext={"Dangerous Goods"}
                      isShowCopy={false}
                    >
                      <img
                        className="mr-1"
                        src={DangerIconTriangle}
                        alt="outOf"
                      />
                    </CustomTooltip>
                  )}
                  Size: {item.containerSize}
                </div>
              </Typography.Text>
            </div>
          </>
        ),
        sorter: (a, b) =>
          a.containerDetail.containerNumber?.localeCompare(
            b.containerDetail.containerNumber
          ),
      },
      {
        title: (
          <div>
            <p className="p-[0px] m-[0px]">Consignee/Receiver</p>
          </div>
        ),
        dataIndex: "doNameDetail",
        sorter: (a, b) =>
          a.doNameDetail.consigneeName?.localeCompare(
            b.doNameDetail.consigneeName
          ),
        sortIcon: CustomSortIcon,
        render: (item) => (
          <div className="flex flex-col w-full">
            <CustomTooltip copytext={normalizeForEdi(item.consigneeName)} isShowCopy>
              <Typography.Text className="!block truncate font-roboto text-sm text-blue-midnight-express leading-5 font-normal max-w-[fit-content]">
                {normalizeForEdi(item.consigneeName)}
              </Typography.Text>
            </CustomTooltip>
            {item?.receiverVatId && item?.receiverName && (
              <CustomTooltip
                copytext={`${item.receiverVatId} - ${item.receiverName}`}
                isShowCopy
              >
                <Typography.Text className="!block truncate font-roboto text-xs text-blue-waikawa-grey leading-5 font-normal max-w-[fit-content]">
                  {item.receiverVatId} - {item.receiverName}
                </Typography.Text>
              </CustomTooltip>
            )}
          </div>
        ),
        ellipsis: true,
      },
      {
        title: (
          <div>
            <p className="p-[0px] m-[0px]">Pick Up Date/</p>
            <p className="p-[0px] m-[0px]">FPOD</p>
          </div>
        ),
        dataIndex: "pickupDetail",
        sorter: (a, b) =>
          dateSorter(a.pickupDetail.pickupDate, b.pickupDetail.pickupDate),
        sortIcon: CustomSortIcon,
        render: (item) => (
          <div className="flex flex-col w-full">
            <Typography.Text className="truncate font-roboto text-sm text-blue-midnight-express leading-5 font-normal">
              {item.pickupDate &&
                dayjs(item.pickupDate).format(SYSTEM_DATE_FORMAT)}
            </Typography.Text>
            <CustomTooltip copytext={item.fpodName} isShowCopy>
              <Typography.Text className="!block truncate font-roboto text-xs text-blue-waikawa-grey leading-5 font-normal max-w-[fit-content]">
                {item.fpodName}
              </Typography.Text>
            </CustomTooltip>
          </div>
        ),
        ellipsis: true,
      },
      {
        title: (
          <div>
            <p className="p-[0px] m-[0px]">Return Date/</p>
            <p className="p-[0px] m-[0px]">Empty Return Depot</p>
          </div>
        ),
        dataIndex: "returnDetail",
        sorter: (a, b) =>
          dateSorter(
            a.returnDetail.returnFullDate,
            b.returnDetail.returnFullDate
          ),
        sortIcon: CustomSortIcon,
        render: (item) => (
          <div className="flex flex-col w-full">
            <Typography.Text className="truncate font-roboto text-sm text-blue-midnight-express leading-5 font-normal">
              {item.returnNumber
                ? item.returnDate && `${item.returnDate} days from pick up date`
                : item.returnFullDate &&
                  dayjs(item.returnFullDate).format(SYSTEM_DATE_FORMAT)}
            </Typography.Text>
            <CustomTooltip copytext={item.emptyReturnDepotName} isShowCopy>
              <Typography.Text className="truncate font-roboto text-xs text-blue-waikawa-grey leading-5 font-normal max-w-[fit-content]">
                {item.emptyReturnDepotName}
              </Typography.Text>
            </CustomTooltip>
          </div>
        ),
        ellipsis: true,
      },
      {
        title: (
          <div>
            <p className="p-[0px] m-[0px]">Status/</p>
            <p className="p-[0px] m-[0px] truncate">Updated At</p>
          </div>
        ),
        dataIndex: "statusUpdateDetail",
        sorter: (a, b) =>
          reversedEDO_STATUS[a.statusUpdateDetail.status].label?.localeCompare(
            reversedEDO_STATUS[b.statusUpdateDetail.status].label
          ),
        sortIcon: CustomSortIcon,
        render: (item) => (
          <div className="flex flex-col">
            <StatusLabel labelStatus={item.status} />
            <Typography.Text className="mt-[4px] font-roboto text-xs text-blue-waikawa-grey leading-4 font-normal">
              {dayjs(item.modifiedDate).format(UPDATE_DATE_FORMAT)}
            </Typography.Text>
          </div>
        ),
      },
      {
        title: (
          <div>
            <p className="p-[0px] m-[0px]">Release No.</p>
          </div>
        ),
        dataIndex: "releaseNo",
        sortIcon: CustomSortIcon,
        render: (item) => (
          <div className="flex flex-col w-full">
            <CustomTooltip copytext={item} isShowCopy>
              <Typography.Text className="truncate font-roboto text-sm text-blue-royal-blue leading-4 font-normal">
                {item}
              </Typography.Text>
            </CustomTooltip>
          </div>
        ),
        ellipsis: true,
      },
      {
        title: "SOC",
        dataIndex: "isSOC",
        render: (item) => (
          <div className="flex justify-center w-full item-center">
            <Typography.Text className="font-roboto text-sm text-violet-revolver leading-4 font-normal">
              {item === null ? "N/A" : item ? "Yes" : "No"}
            </Typography.Text>
          </div>
        ),
        ellipsis: true,
      },
      {
        title: (
          <div>
            <p className="p-[0px] m-[0px]">Part</p>
            <p className="p-[0px] m-[0px]">-load</p>
          </div>
        ),
        dataIndex: "isPartLoad",
        render: (item) => (
          <div className="flex justify-center w-full item-center">
            <Typography.Text className="font-roboto text-sm text-violet-revolver leading-4 font-normal">
              {item ? "Yes" : "No"}
            </Typography.Text>
          </div>
        ),
        ellipsis: true,
      },
      {
        title: "",
        dataIndex: "",
        render: (
          _text: string,
          record: ExpandedChildDataType,
          _index: number
        ) => <EdoMoreActionButton record={record} />,
      },
    ],
    [role]
  );

  return role === UserRole.Depot
    ? columns.filter(
        (item: any) =>
          item.dataIndex !== "pickupDetail" && item.dataIndex !== "releaseNo"
      )
    : columns;
};
